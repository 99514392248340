import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Form from '~components/Form'
import Icon from '~components/Icon'
import S from '~components/seo'
import { Link } from 'gatsby'
import useAuth from '~hooks/useAuth'
import DATA from './constants'

import * as s from './Register.module.scss'

const Register = () => {
  const auth = useAuth()
  const isAuthenticated = !!auth.token
  const { t } = useTranslation()

  return (
    <section className={s.register_wrapper}>
      <S title={t(`titles.register`)} />
      <div className={s.register_ill}>
        <Link to="/" className={s.register_icon}>
          <Icon name="logo_footer" size={(141, 77)} />
        </Link>
        <StaticImage
          src="./img/reg-min.png"
          alt="registration"
          placeholder="tracedSVG"
        />
        <h1>{t(`registration.title`)}</h1>
        <p>{t(`registration.descr`)}</p>
      </div>
      <div className={s.register_form}>
        <Badge bg="secondary">{t(`registration.form.title`)}</Badge>
        <Form
          isAuth={isAuthenticated}
          {...auth}
          data={DATA}
          variant="register"
        />
        <Button href="/login" variant="outline-success">
          {t(`registration.btn`)}
        </Button>
      </div>
    </section>
  )
}

export default Register
