import EMAIL_PATTERN from '~utils'

const DATA = [
  {
    name: 'name',
    type: 'text',
  },
  {
    name: 'email',
    type: 'email',
    pattern: EMAIL_PATTERN,
  },
  {
    name: 'mobile',
    type: 'text',
  },
  {
    name: 'password',
    type: 'password',
  },
]

export default DATA
